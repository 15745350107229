import { Insurance } from './Insurance';

export const DHLParcelShopDefaultValues = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  shipmentStartHour: null,
  shipmentEndHour: null,
  content: '',
  comment: '',
  reference: '',
  insurance: Insurance,
};
