export const dropOffTypes = [
  {
    id: 'REGULAR_PICKUP',
    name: 'Utworzenie przesyłki bez restrykcji',
  },
  {
    id: 'REQUEST_COURIER',
    name: 'Utworzenie przesyłki i zamówienie kuriera',
  },
];
