import { DHLParcelShopDefaultValues } from './DHLParcelShopDefaultValues';
import { Billing } from './Billing';

export const DHLParcelShop = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'dhlparcelshop',
  integration: 'DHLParcelShop',
  userName: '',
  urlAddress: '',
  billing: Billing,
  labelType: '',
  dropOffType: '',
  serviceType: '',
  additionalServices: { services: [] },
  fieldsMappings: { mappings: [] },
  defaultValues: DHLParcelShopDefaultValues,
};
