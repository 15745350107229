export const labelTypes = [
  {
    id: 'BLP',
    name: 'Etykieta BLP w formacie PDF',
  },
  {
    id: 'ZBLP',
    name: 'Etykieta BLP w formacie drukarek Zebra',
  },
];
